<template>
  <div class="services">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="background: url('img/services/header-services.jpg'); background-size: cover"
    >
      <div class="viewport">
        <h1>Expertise sourced by experts</h1>
        <p>
          We provide growth companies with suitable, high-performance candidates for permanent,
          contract, and freelance placements.
        </p>
      </div>
      <div class="overlay"></div>
    </div>

    <div class="services-content">
      <div class="viewport">
        <div class="row">
          <div class="col-md-4">
            <h3 style="margin-bottom: 65px">Direct hiring</h3>
            <p>
              Our direct hiring services align candidate skills, experience and personality to match
              your business needs and company culture. An ideal solution for long-term and permanent
              placements.
            </p>
            <router-link class="btn btn-primary" to="/services/direct-hiring"
              >Read more</router-link
            >
          </div>
          <div class="col-md-4">
            <h3>Dedicated nearshore team</h3>
            <p>
              Tailor-made and dedicated teams in Bulgaria that offer a proven and effective IT
              nearshoring setup that enables you to extend your capabilities in a cost-effective,
              efficient and sustainable way.
            </p>
            <router-link class="btn btn-primary" to="/services/dedicated-nearshore-team"
              >Read more</router-link
            >
          </div>
          <div class="col-md-4">
            <h3>Freelancing network</h3>
            <p>
              We source highly-skilled IT freelancers for both short and mid-term projects.
              Following your brief, we present capable and carefully assessed candidates with
              suitable availability.
            </p>
            <router-link class="btn btn-primary" to="/services/freelancing-network"
              >Read more</router-link
            >
          </div>
        </div>
      </div>
    </div>
    <div
      class="background-mid-section d-flex align-items-center"
      style="background: url('img/services/disciplines-bg.jpg'); background-size: cover"
    >
      <div class="viewport">
        <h3>A wide range of diciplines and technologies</h3>
        <p>Learn more how we can help your bussines in differend technical niches.</p>
        <router-link to="/expertise" class="btn btn-primary">Read more</router-link>
      </div>
      <div class="overlay"></div>
    </div>

    <div class="viewport">
      <div class="pricing-table table-responsive">
        <table class="table table-striped">
          <thead class="thead-dark">
            <tr>
              <th scope="col">Features</th>
              <th class="text-center" scope="col">Direct Hiring</th>
              <th class="text-center" scope="col">Nearshoring</th>
              <th class="text-center" scope="col">Freelancing</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td scope="row">Duration</td>
              <td class="text-center">Long-Term</td>
              <td class="text-center">Mid/Long-term</td>
              <td class="text-center">Short/Mid-term</td>
            </tr>
            <tr>
              <td scope="row">Flexibility</td>
              <td class="text-center">Low</td>
              <td class="text-center">Mid</td>
              <td class="text-center">High</td>
            </tr>
            <tr>
              <td scope="row">Management</td>
              <td class="text-center">Customer</td>
              <td class="text-center">Shared</td>
              <td class="text-center">Customer</td>
            </tr>
            <tr>
              <td scope="row">Office</td>
              <td class="text-center">Customer</td>
              <td class="text-center">Comstream</td>
              <td class="text-center">Home</td>
            </tr>
            <tr>
              <td scope="row">Equipment</td>
              <td class="text-center">Customer</td>
              <td class="text-center">Comstream</td>
              <td class="text-center">Own</td>
            </tr>
            <tr>
              <td scope="row">Model</td>
              <td class="text-center">Recruiting Fee</td>
              <td class="text-center">Monthly</td>
              <td class="text-center">Hourly</td>
            </tr>
            <tr>
              <td scope="row">Cost level</td>
              <td class="text-center">€€</td>
              <td class="text-center">€</td>
              <td class="text-center">€€</td>
            </tr>
            <tr>
              <td scope="row"></td>
              <td class="text-center">
                <router-link to="/services/direct-hiring" class="btn btn-primary"
                  >Read more</router-link
                >
              </td>
              <td class="text-center">
                <router-link to="/services/dedicated-nearshore-team" class="btn btn-primary"
                  >Read more</router-link
                >
              </td>
              <td class="text-center">
                <router-link to="/services/direct-hiring" class="btn btn-primary"
                  >Read more</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Direct Hiring, Nearshore Teams and Freelancers | Comstream',
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
.services {
  .services-content {
    text-align: center;
    padding: 70px 0;
    h3 {
      font-weight: 600;
      font-size: 2.25rem;
      color: #000;
      margin: 20px 40px;
    }
  }
  .pricing-table {
    padding: 70px 0;
    .table {
      .thead-dark th {
        background: #a93224;
        border-color: #a93224;
        font-size: 24px;
        color: #fff;
      }
      td {
        padding: 20px 12px !important;
        border: none;
        font-size: 17px;
      }
      tbody {
        border: none;
      }
      tr {
        border: none;
      }
    }
  }
}
@media only screen and (max-width: 576px) {
  .services {
    .services-content {
      .col-md-4 {
        margin-bottom: 40px;
      }
      h3 {
        font-size: 2rem;
        margin-bottom: 12px !important;
      }
    }
  }
}
</style>
